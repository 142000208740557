<template>
  <Transition name="skeleton" mode="out-in">
    <div v-if="loaded" key="B2Ccontent">
      <!-- First article in a list -->
      <template v-if="props.index === 0">
        <NuxtLink
          :to="articleRouteObject"
          class="relative flex flex-col overflow-hidden group"
        >
          <B2CArticleTeaserImage
            :article-image="articleImage"
            :article-title="articleTitle"
            :is-first-article="true"
            :is-video="isVideo"
            :preload="props.preload"
            :lazy="props.lazy"
            is-framed
          />
          <div class="w-full md:w-9/10 py-4 md:py-8">
            <B2CArticleTeaserLabel
              class="mb-1 md:mb-3"
              :type="type"
              :article="props.article"
              :is-first-article="true"
              :is-framed="true"
              :is-video="isVideo"
            />

            <h2
              class="text-xl/6 md:text-5xl/[56px] font-bold font-sans text-white -tracking-[0.005em] group-hover:underline decoration-2 text-pretty"
            >
              {{ articleTitle }}
            </h2>
          </div>
        </NuxtLink>
      </template>
      <!-- Not first article in a list -->
      <template v-else>
        <NuxtLink
          :to="articleRouteObject"
          class="relative flex group rounded-none"
        >
          <B2CArticleTeaserImage
            :article-image="articleImage"
            :article-title="articleTitle"
            :is-video="isVideo"
            :preload="props.preload"
            :lazy="props.lazy"
            is-framed
            row-layout
          />
          <div class="w-full ml-3">
            <B2CArticleTeaserLabel
              class="mb-1"
              :type="type"
              :article="article"
              is-framed
              :is-video="isVideo"
            />

            <h2
              class="text-[13px]/4 md:text-2xl/[30px] font-sans text-white -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2 text-pretty"
            >
              {{ articleTitle }}
            </h2>
          </div>
        </NuxtLink>
      </template>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    index: number // The index of the article in the list. Need to differ the first article from the rest
    lazy?: boolean
    preload?: boolean
  }>(),
  {
    lazy: true,
    preload: false,
  }
)

const articleTitle = computed(
  () =>
    props.article.Headline ||
    props.article.HeadLine ||
    props.article.headline ||
    props.article.Title ||
    ''
)

const articleImage = computed(
  () =>
    props.article.ImageUrl ||
    props.article.imageurl ||
    props.article.imageUrl ||
    ''
)

const loaded = computed(() => typeof props.article === 'object')

const type = computed(() => {
  const articleType = props.article.Type ?? props.article.type
  if (articleType != undefined) {
    return getArticleType(articleType)
  } else {
    return 'article'
  }
})

const articleRouteObject = computed(() => {
  return {
    name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
    params: indexStore.currentPaperSlug
      ? {
          paper: indexStore.currentPaperSlug,
          id: props.article.UrlKey || props.article.urlKey,
        }
      : { id: props.article.UrlKey || props.article.urlKey },
  }
})

const isVideo = computed(
  () =>
    /video/.test(type.value) ||
    Boolean(props.article.ToppictHTML?.trim()) ||
    Boolean(props.article.VideoProvider?.trim())
)
</script>
