<template>
  <NuxtLink
    :to="{
      name: 'article',
      params: {
        id: article.UrlKey ?? article.urlKey,
      },
    }"
    class="grid p-3 md:p-0 grid-cols-[auto_1fr] items-start md:grid-rows-[auto_1fr] md:grid-cols-1 md:items-center bg-white border border-gray-300 rounded-xl group md:overflow-hidden"
    :class="{
      'rounded-lg md:rounded-xl overflow-hidden': props.isFirstArticle,
    }"
  >
    <div
      class="md:bg-gray-100"
      :class="{
        'md:py-8 md:flex md:items-center md:justify-center':
          !props.isFirstArticle,
      }"
    >
      <template v-if="props.isFirstArticle">
        <div class="hidden md:block">
          <B2CArticleTeaserImage
            :article-image="podcastImage"
            :article-title="podcastTitle"
            :is-first-article="true"
          />
        </div>

        <NuxtImg
          class="h-20 w-20 md:h-36 md:w-36 rounded object-center md:hidden"
          :src="
            article.Podcast
              ? config.public.site.legacydomain + article.Podcast?.image
              : podcastImage // Fallback to article image if no podcast image
          "
        />
      </template>
      <NuxtImg
        v-else
        class="h-20 w-20 md:h-36 md:w-36 rounded object-center"
        :src="
          article.Podcast
            ? config.public.site.legacydomain + article.Podcast?.image
            : podcastImage // Fallback to article image if no podcast image
        "
      />
    </div>
    <div
      class="flex justify-around gap-2 items-center md:items-center md:p-6 ml-3 md:ml-0"
    >
      <div>
        <B2CArticleTeaserLabel
          type="podcast"
          :article="props.article"
          class="mb-1"
        />
        <h2
          class="text-sm md:text-2xl/[30px] font-bold font-sans text-[#000000] -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2"
        >
          {{ podcastTitle }}
        </h2>
      </div>
      <div
        class="rounded-full cursor-pointer bg-red flex items-center justify-center shrink-0 h-8 w-8 md:h-10 md:w-10 mr-2 transition-shadow hover:shadow-[0_0_4px_4px_rgba(255,0,0,0.3)]"
        @click.prevent="toggleAudio"
      >
        <IconPause
          v-if="isPlaying && url === podcastFile"
          id="icon-pause"
          width="18"
          height="18"
        />
        <IconPlay v-else id="icon-play" width="18" height="18" />
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import type { Podcast } from '~/typesAuto/apicore/v1'
import type { ContentPodcast } from '~/typesManual/content_api/podcast'

import { ContentArticle } from '~/typesManual/content_api/article'
import IconPlay from '~/assets/icons/play.svg?component'
import IconPause from '~/assets/icons/pause.svg?component'

const dataLayer = useDataLayer()
const config = useRuntimeConfig()
const { isPlaying, play, url } = useAudioPlayer()

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    isFirstArticle?: boolean
  }>(),
  {
    isFirstArticle: false,
  }
)

const podcastTitle = computed(
  () =>
    props.article.Headline ||
    props.article.HeadLine ||
    props.article.headline ||
    props.article.Title ||
    ''
)
const podcastImage = computed(
  () =>
    props.article.ImageUrl ||
    props.article.imageUrl ||
    props.article.imageurl ||
    ''
)

const podcastFile = computed(() => props.article.Podcast?.file)

const toggleAudio = () => {
  // If playing, stop
  if (isPlaying.value && url.value === podcastFile.value) {
    isPlaying.value = false
  } else {
    playAudio()
  }
}

const isPodcastType = (item: ContentPodcast | Podcast): item is Podcast => {
  return (item as Podcast).recordId !== undefined
}

const playAudio = () => {
  const article = props.article

  const podcast = article.podcast ?? article.Podcast
  if (!podcast) return

  // Track plays
  const headline =
    article.Headline ?? article.HeadLine ?? article.headline ?? ''

  let recordId: string | null | undefined = undefined
  if (isPodcastType(podcast)) {
    recordId = podcast.recordId
  } else {
    recordId = podcast.recordid
  }

  if (recordId) {
    dataLayer.podcast.play(recordId, headline)
  }

  play({
    url: podcastFile.value ?? '',
    imageUrl: `${config.public.site.legacydomain}${podcast.image}`,
    title: headline,
  })
}
</script>
