<template>
  <NuxtLink
    :to="{
      name: isPaperRoute ? 'papers' : 'subscription',
    }"
    class="button-l border-b border-gray-300 flex justify-center items-center h-[36px] sm:h-[38px] px-5 bg-blue text-white whitespace-nowrap cursor-pointer sm:rounded-full sm:border sm:bg-blue hover:bg-denimblue hover:text-white"
  >
    {{ isPaperRoute ? $t('CreateProfile') : $t('BuyAbonnement') }}
  </NuxtLink>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()

const isPaperRoute = computed(() => {
  return indexStore.currentPaper && indexStore.currentPaper.RecordId !== 1
})
</script>
