<template>
  <div class="mt-5 md:mt-8">
    <div class="container no-print flex flex-col gap-y-4 md:gap-y-8 mb-10">
      <B2CListArticles
        :articles="firstArticleSection.slice(0, 3)"
        :lazy="false"
        :preload-first-image="true"
      />

      <ListJobs b2c :count="8" :random="true" />

      <div class="flex flex-col gap-y-6 md:gap-y-8">
        <B2CListArticles
          :articles="firstArticleSection.slice(3, 5)"
          :reverse-order="true"
          :first-article-mobile-small="true"
          :preload-first-image="true"
        >
          <B2CArticleSponsoredTeaser :placement-id="'0'"
        /></B2CListArticles>

        <B2CListArticles
          :articles="firstArticleSection.slice(5, 8)"
          :same-size="true"
        />
      </div>

      <AdsConceptAd
        class="no-print hidden lg:block"
        id="altinget-lb2"
        :full-width="true"
      />
      <AdsConceptAd
        class="no-print lg:hidden order-15"
        id="altinget-mob2"
        :full-width="true"
      />

      <div
        v-if="articleSections && articleSections.length > 1"
        class="space-y-4"
      >
        <div
          v-for="(articleSection, index) in moreArticleSections"
          :key="index"
        >
          <B2CListArticles
            :articles="articleSection?.slice(0, 3)"
            class="mb-6"
          />

          <div class="flex flex-col gap-y-6 md:gap-y-8">
            <B2CListArticles
              :articles="articleSections ? articleSection.slice(3, 5) : []"
              :reverse-order="true"
              :first-article-mobile-small="true"
            >
              <B2CArticleSponsoredTeaser :placement-id="(index + 1).toString()"
            /></B2CListArticles>

            <B2CListArticles
              :articles="articleSections ? articleSection.slice(5, 8) : []"
              :same-size="true"
              class="mb-6"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center" v-if="displayLoadMoreButton">
        <CommonButton @click="onLoadMoreClick">
          {{ $t('ShowMore') }}
        </CommonButton>
      </div>
      <NewsletterSignup class="mt-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const config = useRuntimeConfig()
const requestUrl = useRequestURL()

const currentOffset = ref(0)
const currentCount = ref(16)

const { data: articles, loadMore } = await useArticles({
  paper: 1,
  frontend: true, // Flags to use Saga API
  count: currentCount.value,
  offset: currentOffset.value,
})

const articleSections = computed(() => {
  if (!articles.value?.length) {
    return []
  }

  const chunkSize = 8

  return articles.value.reduce<ContentArticle[][]>(
    (sections, article, index) => {
      const chunkIndex = Math.floor(index / chunkSize)

      if (!sections[chunkIndex]) {
        sections[chunkIndex] = [] // Initialize a new chunk
      }

      sections[chunkIndex].push(article)

      return sections
    },
    []
  )
})

const firstArticleSection = computed(() => {
  return articleSections.value?.[0] ?? []
})

const moreArticleSections = computed(() => {
  return articleSections.value?.filter((_, index) => index > 0)
})

const displayLoadMoreButton = ref<boolean>(true)

const onLoadMoreClick = async () => {
  await loadMore(16)
}

useHead(() => {
  // Have to use config.public.site here, due to composables not working in useHead
  const siteConfig = config.public.site

  return createBasicHeadMetaData(siteConfig, requestUrl)
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
.fade-enter-to,
.fade-leave-from {
  @apply opacity-100;
}
</style>
