<template>
  <div class="flex items-center" ref="nicheModal">
    <div class="cursor-pointer z-20" @click="nicheMediaToggle">
      <span
        class="flex flex-col list-label items-center justify-center !font-medium select-none transition-color duration-200"
        :class="{ 'text-blue': isMenuOpen, 'hover:text-gray-600': !isMenuOpen }"
      >
        <div class="relative h-5 w-6 pt-px">
          <NichemediaIcon
            class="transition-opacity duration-200"
            :class="{ 'opacity-0': isMenuOpen }"
          />
          <div
            :class="{ 'opacity-0': !isMenuOpen }"
            class="transition-opacity duration-200 w-6"
          >
            <span class="absolute w-full h-px rotate-45 top-[9px] bg-blue" />
            <span class="absolute w-full h-px -rotate-45 top-[9px] bg-blue" />
          </div>
        </div>
        <span class="mt-2">
          {{ $t('Papers') }}
        </span>
      </span>
    </div>

    <div
      :class="{
        'opacity-100 translate-y-10': isMenuOpen,
        'opacity-0 translate-y-12 pointer-events-none': !isMenuOpen,
      }"
      class="rounded-b top-[13px] md:top-7 right-0 md:right-40 overflow-y-scroll custom-scrollbar z-10 w-full md:w-80 h-[calc(100dvh-66px)] md:h-auto md:max-h-[86vh] py-5 absolute bg-white md:shadow transition-all duration-200 transform"
    >
      <ol>
        <template v-if="papers.userPapers.length > 0">
          <div class="list-label opacity-50 px-5 mb-4">
            {{ $t('MyPapers') }}
          </div>
          <li v-for="paper in papers.userPapers" :key="paper.RecordId">
            <NuxtLink
              :to="{ name: 'paper', params: { paper: paper.Url } }"
              class="list-title-xxxs !font-medium flex py-3 px-5 hover:bg-gray-100"
              :class="{
                'text-gray-500 pointer-events-none':
                  $route.params.paper === paper.Url,
              }"
              @click="nicheMediaToggle"
            >
              <img
                src="/A_Logomark.svg"
                alt="Altinget logo"
                class="w-4 h-4 mr-2"
              />
              {{ paper.Name }}
            </NuxtLink>
          </li>
        </template>
        <CommonDivider
          class="mb-8 px-5"
          v-if="
            papers.unsubscribedPapers.length > 0 && papers.userPapers.length > 0
          "
        />
        <template v-if="papers.unsubscribedPapers.length > 0">
          <div class="list-label opacity-50 px-5 mb-4">
            {{
              papers.userPapers.length > 0 ? $t('OtherPapers') : $t('Papers')
            }}
          </div>

          <li v-for="paper in papers.unsubscribedPapers" :key="paper.RecordId">
            <NuxtLink
              :to="{ name: 'paper', params: { paper: paper.Url } }"
              class="list-title-xxxs !font-medium flex py-3 px-5 hover:bg-gray-100"
              :class="{
                'text-gray-500 pointer-events-none':
                  $route.params.paper === paper.Url,
              }"
              @click="nicheMediaToggle"
            >
              <img
                src="/A_Logomark.svg"
                alt="Altinget logo"
                class="w-4 h-4 mr-2"
              />
              {{ paper.Name }}
            </NuxtLink>
          </li>
        </template>
      </ol>
    </div>
  </div>
</template>
<script setup lang="ts">
import NichemediaIcon from '@/assets/icons/icon-nichemedia.svg?component'
import { onClickOutside } from '@vueuse/core'

// Properties
const props = withDefaults(
  defineProps<{
    headerVisible: boolean
  }>(),
  { headerVisible: false }
)

// Composables
const indexStore = useIndexStore()
const userStore = useUserStore()
// Refs
const isMenuOpen = ref(false)
const nicheModal = ref<HTMLDivElement>()

// Computed
const papers = computed(() => {
  if (userStore.user && userStore.user.hasActiveSubscription) {
    const userPapers = indexStore.activePapers.filter((paper) =>
      userStore.hasSubscription(paper.RecordId)
    )
    const unsubscribedPapers = indexStore.activePapers.filter(
      (paper) => !userPapers.find((p) => p.RecordId === paper.RecordId)
    )
    return {
      userPapers: [...userPapers],
      unsubscribedPapers: [...unsubscribedPapers],
    }
  }
  return {
    userPapers: [],
    unsubscribedPapers: [...indexStore.activePapers],
  }
})
// Watchers
watch(
  () => props.headerVisible,
  (newVal) => {
    if (!newVal) {
      isMenuOpen.value = false
    }
  }
)

// Methods
const nicheMediaToggle = () => {
  isMenuOpen.value = !isMenuOpen.value
}
// Calls
onClickOutside(nicheModal, () => {
  isMenuOpen.value = false
})
</script>
